import { getInstance } from '../../../common/api/spidertracks-sdk';
import { StandardThunk } from '../../../store';
import {
  setConversations,
  setContacts,
  updateLastReadMessageForContact,
  updateIsAllMessagesLoadedForConversation,
  updateConversation,
  addMessagesToConversation,
  addSendingMessageToConversation,
  setUnreadMessageCountForConversation,
  setContactImage
} from './spiderTxt';
import {
  getContacts,
  getConversations,
  getLastConversationsPolledOn,
  getLastFullPolledOn,
  getReadMessageMap
} from '../../selectors/spiderTxt';
import { getUserData } from '../../selectors/userData';
import {
  Contact,
  ContactType,
  Message,
  MessageStatus,
  MessageType,
  UIContact,
  UIConversation,
  UIMessage
} from '../../../types/spidertxt';
import { UserData } from '../../types';
import moment from 'moment';

const SYSTEM_USER_ID = '00000000-0000-0000-0000-000000000000';

const getUIMessages = (messages: Message[], contacts: Contact[], user: UserData): UIMessage[] => {
  const validMessages: UIMessage[] = [];

  for (let message of messages) {
    if (message.id == null || message.timestamp == null || message.body == null) {
      continue;
    }

    const messageTime = moment(message.timestamp).tz(user.timezone);
    const date = messageTime.format(user.dateFormat.replace('_', '/').replace('_', '/'));
    const time = messageTime.format('HH:mm');

    if (message.attributionId == SYSTEM_USER_ID) {
      // system message
      validMessages.push({
        ...message,
        type: MessageType.SYSTEM,
        messageDay: date,
        messageTime: time,
        senderDisplayName: 'System'
      });
      continue;
    }

    let contact = contacts.find(contact => contact.id === message.contactId);
    if (contact == undefined) {
      console.error('Could not find contact for message', message);
      continue;
    }

    let sender: Contact | undefined = contact;
    if (contact.contactType == ContactType.GROUP) {
      if (message.attributionId) {
        sender = contacts.find(contact => contact.id === message.attributionId);
        if (sender == undefined || sender.id != user.id) {
          message.type = MessageType.RECEIVED;
        } else {
          message.type = MessageType.SENT;
        }
      }
    }

    let senderDisplayName = sender ? sender.displayName : 'User removed';
    if (sender != undefined && contact.contactType == ContactType.GROUP) {
      if (!contact.memberIds.includes(sender.id)) {
        senderDisplayName += ' (Left group)';
      }
    }

    validMessages.push({
      ...message,
      messageDay: date,
      messageTime: time,
      senderDisplayName
    });
  }

  return validMessages;
};

const INDIVIDUAL_AVATAR = '/spidertxt-images/default-user.png';
const GROUP_AVATAR = '/spidertxt-images/default-group.png';
const getUIContact = (contact: Contact): UIContact => {
  if (contact.contactType == ContactType.GROUP) {
    return {
      contactType: contact.contactType,
      displayName: contact.displayName,
      id: contact.id,
      imageUrl: GROUP_AVATAR,
      memberIds: contact.memberIds,
      organisationId: contact.organisationId,
      organisationName: contact.organisationName
    };
  } else {
    return {
      contactType: contact.contactType,
      displayName: contact.displayName,
      id: contact.id,
      imageUrl: INDIVIDUAL_AVATAR,
      avatarUrl: contact.avatarUrl,
      organisations: contact.organisations
    };
  }
};

const getCurrentUserContact = (userData: UserData): Contact => {
  return {
    id: userData.id,
    displayName: userData.displayName,
    contactType: ContactType.INDIVIDUAL,
    avatarUrl: undefined,
    organisations: []
  };
};

const loadingImagesFor: Set<string> = new Set();
export const loadAvatar = (contact: UIContact): StandardThunk<void> => async (
  dispatch,
  getState
) => {
  if (contact.imageUrl != INDIVIDUAL_AVATAR && contact.imageUrl != GROUP_AVATAR) {
    /** Contact already has image loaded */
    return;
  }
  if (contact.contactType == ContactType.GROUP || contact.avatarUrl == undefined) {
    /** Nothing to load for them */
    return;
  }
  if (loadingImagesFor.has(contact.id)) {
    return;
  }
  loadingImagesFor.add(contact.id);
  const sdk = getInstance();
  const imageUrl = await sdk.getContactService().getContactImage(contact);
  if (imageUrl != undefined) {
    dispatch(setContactImage({ contactId: contact.id, imageUrl }));
  }
  loadingImagesFor.delete(contact.id);
};

let lastPopulatedContactsAndConversations = 0;
export const populateContactsAndConversations = (): StandardThunk<void> => async (
  dispatch,
  getState
) => {
  /** Very rudimentary debouncing to prevent spamming the API by leaving + entering the page */
  if (Date.now() - lastPopulatedContactsAndConversations < 10000) {
    return;
  }
  lastPopulatedContactsAndConversations = Date.now();

  const sdk = getInstance();
  const currentUser = getUserData(getState());

  const contactsPromise = sdk.getContactService().getContacts();
  const allConversations = await sdk
    .getConversationService()
    .getAllActiveConversations(currentUser.id);
  const contacts = await contactsPromise;
  const payload: {
    contacts: { [key: string]: UIContact };
    conversations: { [key: string]: UIConversation };
  } = { contacts: {}, conversations: {} };

  contacts.forEach(contact => {
    payload.contacts[contact.id] = getUIContact(contact);
  });

  allConversations.forEach(conversation => {
    if (conversation.contactId == currentUser.id) {
      return;
    }
    const contact = contacts.find(contact => contact.id === conversation.contactId);
    if (!contact) {
      // We sometimes get conversations for contacts that don't exist. We don't want their conversations.
      return;
    }
    payload.conversations[conversation.contactId] = {
      ...conversation,
      messages: getUIMessages(conversation.messages, contacts, currentUser),
      isAllMessagesLoaded: false
    };
  });
  dispatch(setContacts(payload));
  dispatch(setConversations(payload));
};

export const refreshConversations = (): StandardThunk<void> => async (dispatch, getState) => {
  const sdk = getInstance();
  const currentUser = getUserData(getState());

  const allConversations = await sdk
    .getConversationService()
    .getAllActiveConversations(currentUser.id);

  const payload: {
    conversations: { [key: string]: UIConversation };
  } = { conversations: {} };

  const contacts = getContacts(getState());

  allConversations.forEach(conversation => {
    if (conversation.contactId == currentUser.id) {
      return;
    }
    const contact = contacts.find(contact => contact.id === conversation.contactId);
    if (!contact) {
      // We sometimes get conversations for contacts that don't exist. We don't want their conversations.
      return;
    }
    payload.conversations[conversation.contactId] = {
      ...conversation,
      messages: getUIMessages(conversation.messages, contacts, currentUser),
      isAllMessagesLoaded: false
    };
  });
  dispatch(setConversations(payload));
};

export const getMoreMessagesForConversation = (
  contactId: string,
  loadingStatusCallback: Function
): StandardThunk<void> => async (dispatch, getState) => {
  const sdk = getInstance();
  const allContacts = getContacts(getState());
  const currentUser = getUserData(getState());
  const allConversations = getConversations(getState());

  const loggedInUserContact = getCurrentUserContact(currentUser);
  const conversation = allConversations.find(conversation => conversation.contactId === contactId);

  if (!conversation || !loggedInUserContact) {
    console.error('getMoreMessagesForConversation: Could not find the conversation');
    return;
  }

  const { isAllMessagesLoaded } = conversation;
  const backendMessages = conversation.messages.filter(el => el.status != MessageStatus.SENDING);
  const oldestMessage = backendMessages[0];

  if (!isAllMessagesLoaded) {
    const messages = await sdk
      .getConversationService()
      .getMoreMessagesForContact(loggedInUserContact.id, conversation.id, oldestMessage?.id);
    if (messages.length == 0 || messages.every(el => el.timestamp == oldestMessage?.timestamp)) {
      dispatch(
        updateIsAllMessagesLoadedForConversation({
          contactId: contactId,
          isAllMessagesLoaded: true
        })
      );
    } else {
      dispatch(
        addMessagesToConversation({
          contactId: contactId,
          messages: getUIMessages(messages, allContacts, currentUser)
        })
      );
    }
  }

  loadingStatusCallback(false);
};

/* to be triggered when a new message is received(on spidertxt event) */
export const processPushMessage = (message: Message): StandardThunk<void> => async (
  dispatch,
  getState
) => {
  console.log('Processing push message', message);
  const allContacts = getContacts(getState());
  let allConversations = getConversations(getState());
  const currentUser = getUserData(getState());

  let conversation = allConversations.find(
    conversation => conversation.id === message.conversationId
  );

  if (!conversation || message.type == MessageType.SYSTEM) {
    if (message.type == MessageType.SYSTEM) {
      console.info('processPushMessage: Updating contacts due to system message');
    } else {
      console.info('processPushMessage: Could not find conversation');
    }
    await dispatch(populateContactsAndConversations());
    allConversations = getConversations(getState());
    conversation = allConversations.find(
      conversation => conversation.id === message.conversationId
    );
    if (conversation == undefined) {
      console.error('processPushMessage: Could not find conversation even after update');
    }
    return;
  }

  dispatch(
    addMessagesToConversation({
      contactId: conversation.contactId,
      messages: getUIMessages([message], allContacts, currentUser)
    })
  );
  dispatch(
    setUnreadMessageCountForConversation({
      conversationId: conversation.id,
      count: conversation.unreadMessageCount
    })
  );
};

/* We send the message and then fetch last 'n' messages for that conversation from the API and update our store */
export const sendMessage = (
  conversationId: number,
  messageBody: string,
  callback: Function
): StandardThunk<void> => async (dispatch, getState) => {
  const allContacts = getContacts(getState());
  const allConversations = getConversations(getState());
  const currentUser = getUserData(getState());

  const conversation = allConversations.find(conversation => conversation.id === conversationId);

  if (!conversation) {
    console.error('sendMessage: Could not find conversation');
    return Promise.reject('sendMessage: Could not find conversation');
  }

  dispatch(
    addSendingMessageToConversation({
      contactId: conversation.contactId,
      message: getUIMessages(
        [
          {
            body: messageBody,
            contactId: conversation.contactId,
            conversationId: conversationId,
            attributionId: currentUser.id,
            id: Math.round(Math.random() * 1_000_000_000).toString(),
            timestamp: Date.now(),
            type: MessageType.SENT,
            status: MessageStatus.SENDING
          }
        ],
        allContacts,
        currentUser
      )[0]
    })
  );

  const conversationService = getInstance().getConversationService();
  try {
    await conversationService.sendMessage({
      conversationId,
      body: messageBody
    });
  } catch (e) {
    console.error('Error sending message', e);
  } finally {
    callback();
  }

  // fetch the last few messages for the conversation, this will include the message we just sent
  const messages = await conversationService.getMoreMessagesForContact(
    currentUser.id,
    conversationId,
    undefined,
    5
  );

  dispatch(
    addMessagesToConversation({
      contactId: conversation.contactId,
      messages: getUIMessages(messages, allContacts, currentUser)
    })
  );
};

export const markMessagesAsRead = (contactId: string): StandardThunk<void> => async (
  dispatch,
  getState
) => {
  const readMessageMap = getReadMessageMap(getState());
  const allConversations = getConversations(getState());
  const conversation = allConversations.find(conversation => conversation.contactId === contactId);

  if (!conversation) {
    console.log('Could not find conversation');
    return;
  }

  const messages = conversation.messages.slice();
  messages.sort((l, r) => r.timestamp - l.timestamp);
  const lastMessage = messages.filter((message: Message) => {
    return message.type !== MessageType.SENT;
  })[0];

  if (!lastMessage) {
    return;
  }
  const lastReadMessageId = readMessageMap[contactId];

  if (lastReadMessageId === lastMessage.id) {
    return;
  } else {
    dispatch(updateLastReadMessageForContact({ contactId, messageId: lastMessage.id }));
  }

  console.log('acking message', lastMessage.body);
  const conversationService = getInstance().getConversationService();
  await conversationService.acknowledgeMessage(conversation.id, lastMessage.id);
  // Updates local read counts
  dispatch(refreshConversations());
};

let contactCreationQueue: string[] = [];
/*
 * Create conversation
 */
export const createConversation = (contact: Contact): StandardThunk<void> => async dispatch => {
  if (contactCreationQueue.includes(contact.id)) {
    return;
  }
  contactCreationQueue.push(contact.id);

  const sdk = getInstance();
  const conversation = await sdk.getConversationService().createConversation(contact);
  if (conversation == undefined) {
    return;
  }

  dispatch(
    updateConversation({
      conversation: {
        contactId: contact.id,
        id: conversation.id,
        messages: [],
        unreadMessageCount: 0,
        isAllMessagesLoaded: false
      }
    })
  );
  contactCreationQueue = contactCreationQueue.filter(id => id !== contact.id);
};

async function waitMs(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
