import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Select } from 'antd';

export const dropdownColumnSpans = {
  full: 24,
  icon: 3,
  title: 21
};

/** Assumes at most 1 asterisk */
const coloriseAsterisk = (label) => {
  let parts = label.split('*');
  if (parts.length === 1) {
    return parts[0];
  }
  return (
    <span>
      {parts[0]}
      <span style={{ color: 'red' }}>*</span>
      {parts[1]}
    </span>
  );
};

export const PrimaryConditionSourceDropdown = ({ sources, selectedSource, getFieldDecorator }) => (
  <Form.Item
    label={coloriseAsterisk('Source*')}
    name={'primaryConditionSource'}
    style={{ textAlign: 'left' }}
    className="mt-3"
  >
    <Row>
      {getFieldDecorator('primaryConditionSource', {
        ...(selectedSource && { initialValue: selectedSource }),
        rules: [
          {
            required: true,
            message: 'Please specify a event trigger source'
          }
        ]
      })(
        <Select
          labelInValue={true}
          showSearch
          style={{ width: '300px' }}
          placeholder={'Select an event trigger source'}
          optionLabelProp="label"
        >
          {sources.map(source => {
            return (
              <Select.Option
                key={source.key}
                value={source.key}
                label={coloriseAsterisk(source.label)}
                style={{ width: '100%' }}
              >
                {coloriseAsterisk(source.label)}
              </Select.Option>
            );
          })}
        </Select>
      )}
    </Row>
  </Form.Item>
);

PrimaryConditionSourceDropdown.propTypes = {
  sources: PropTypes.array,
  getFieldDecorator: PropTypes.func
};

PrimaryConditionSourceDropdown.displayName = 'PrimaryConditionSourceDropdown';
