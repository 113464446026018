import React, { useEffect, useState } from 'react';
import { Drawer, Form, Checkbox, Row } from 'antd';
import { ConfirmationButtons } from '../../common/form';
import { EventNameInput } from './EventNameInput';
import { EventTypeDropdown } from './EventTypeDropdown';
import { PrimaryConditionSourceDropdown } from './PrimaryConditionSourceDropdown';
import { AircraftSelector } from '../../common/form/AircraftSelector';
import {
  buildAircraftOptions,
  getConditionTypesFromEventRuleType,
  getDefaultConditionSourceForType,
  getEventRuleTypeFromConditions
} from './utils';
import { ParameterWithThresholds } from './ParameterWithThresholds';
import {
  getInsightRuleConditionSourceOptions,
  getPrimaryConditionSourceLabel,
  getPrimaryConditionSourceOptions
} from './getPrimaryConditionSourceOptions';
import './styles.scss';
import { EventType } from '../../../common/api/spidertracks-sdk/private/services/EventRulesService';
import { PublicAircraftData } from '../../../common/api/spidertracks-sdk/types/AircraftData';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { TableRow } from './EventsConfigTable';
import { useSelector, useDispatch } from 'react-redux';
import {
  getInsightEventParameters,
  getInsightUserData
} from '../../../redux/selectors/insightRules';
import {
  DisplayInsightRuleCondition,
  InsightRule,
  InsightRuleToCreate
} from '../../../types/insightRules';
import { createInsightRule, updateInsightRule } from '../../../redux/slice/insightRules';
import { eventRuleCreateSuccess, eventRuleUpdateSuccess } from '../../../helpers';
import { UserData } from '../../../redux/types';
import { getUserUnitForConditionType } from '../../../redux/mapper';
import {
  InsightRuleConditionSource,
  InsightRuleConditionType
} from '../../../common/api/spidertracks-sdk/private/services/InsightRulesService';
import { useParams } from 'react-router-dom';

interface FieldValues {
  ruleName: string;
  displayThresholds?: {
    [key: string]: number;
  };
  severityDisplayThresholds?: {
    [key: string]:
      | {
          low: number;
          medium: number;
          high: number;
        }
      | number;
  };
  aircraft: string[];
  primaryConditionSource?: { key: string };
  eventType: {
    /** Actual event type selected */
    key: string;
  };
}

function constructRule(
  organisationId: string,
  userData: UserData,
  fieldValues: FieldValues,
  aircraftOptions: any,
  selectedRule: TableRow | undefined
) {
  const allAircraft = fieldValues.aircraft.length === aircraftOptions.length;
  const severityEnabled = fieldValues.severityDisplayThresholds != undefined;

  const [primaryType, secondaryType] = getConditionTypesFromEventRuleType(
    fieldValues.eventType.key
  );
  let primarySource: InsightRuleConditionSource =
    getDefaultConditionSourceForType(primaryType) ??
    (fieldValues.primaryConditionSource!.key as InsightRuleConditionSource);

  const primaryChange: {
    value: number;
    low: number;
    medium: number;
    high: number;
  } = { high: 0, low: 0, medium: 0, value: 0 };
  let secondaryChange: number = 0;
  if (secondaryType != undefined) {
    if (severityEnabled) {
      secondaryChange = (fieldValues.severityDisplayThresholds! as any)[secondaryType];
    } else {
      secondaryChange = fieldValues.displayThresholds![secondaryType];
    }
  }
  if (severityEnabled) {
    primaryChange.value = (fieldValues.severityDisplayThresholds![primaryType] as any).low;
    primaryChange.low = (fieldValues.severityDisplayThresholds![primaryType] as any).low;
    primaryChange.medium = (fieldValues.severityDisplayThresholds![primaryType] as any).medium;
    primaryChange.high = (fieldValues.severityDisplayThresholds![primaryType] as any).high;
  } else {
    primaryChange.value = fieldValues.displayThresholds![primaryType];
    primaryChange.low = fieldValues.displayThresholds![primaryType];
    primaryChange.medium = fieldValues.displayThresholds![primaryType];
    primaryChange.high = fieldValues.displayThresholds![primaryType];
  }

  const primary: DisplayInsightRuleCondition = {
    displayThreshold: {
      thresholdUnit: getUserUnitForConditionType(primaryType, userData),
      thresholdValue: primaryChange.low,
      severityEnabled: severityEnabled,
      thresholdValueLow: primaryChange.low,
      thresholdValueMedium: primaryChange.medium,
      thresholdValueHigh: primaryChange.high
    },
    source: primarySource,
    type: primaryType
  };
  let secondary: DisplayInsightRuleCondition | undefined = undefined;

  if (secondaryType) {
    const secondarySource = getDefaultConditionSourceForType(secondaryType)!;
    secondary = {
      displayThreshold: {
        thresholdUnit: getUserUnitForConditionType(secondaryType, userData),
        thresholdValue: secondaryChange,
        severityEnabled: false, // secondary threshold never has severity
        thresholdValueLow: secondaryChange,
        thresholdValueMedium: secondaryChange,
        thresholdValueHigh: secondaryChange
      },
      source: secondarySource,
      type: secondaryType!
    };
  }

  return {
    id: selectedRule?.id,
    organisationId,
    primaryCondition: primary,
    secondaryCondition: secondary,
    allAircraft,
    aircraftIds: allAircraft ? [] : fieldValues.aircraft,
    enabled: true,
    name: fieldValues.ruleName
  };
}

function constructUpdateRule(
  organisationId: string,
  userData: UserData,
  fieldValues: FieldValues,
  aircraftOptions: any,
  selectedRule: TableRow | undefined
): InsightRule {
  return constructRule(
    organisationId,
    userData,
    fieldValues,
    aircraftOptions,
    selectedRule
  ) as InsightRule;
}

function constructCreateRule(
  organisationId: string,
  userData: UserData,
  fieldValues: FieldValues,
  aircraftOptions: any
): InsightRuleToCreate {
  return constructRule(
    organisationId,
    userData,
    fieldValues,
    aircraftOptions,
    undefined
  ) as InsightRuleToCreate;
}

export interface EventsConfigDrawerProps {
  form: WrappedFormUtils;
  aircraft: PublicAircraftData[];
  isEditForm: boolean;
  visibility: boolean;
  onClose: Function;
  eventTypes: EventType[];
  selectedEventRule?: TableRow;
  save: Function;
  insightsSafetyEnabled: boolean;
}
interface OrganisationIdWrapper {
  organisationId: string;
}

/** Is not exported as is but wrapped in antd form, see bottom of file */
export const EventsConfigDrawer = (props: EventsConfigDrawerProps) => {
  const {
    eventTypes,
    form,
    selectedEventRule,
    isEditForm,
    aircraft,
    visibility,
    insightsSafetyEnabled,
    onClose
  } = props;
  const { validateFields, getFieldDecorator, setFieldsValue, setFields } = form;

  const [isSaving, setIsSaving] = useState(false);
  const { organisationId } = useParams<OrganisationIdWrapper>();
  const dispatch = useDispatch();
  const eventType = selectedEventRule
    ? getEventRuleTypeFromConditions(selectedEventRule)
    : undefined;
  const [selectedEventType, setSelectedEventType] = useState(
    isEditForm ? { key: eventType, label: selectedEventRule?.name } : undefined
  );

  const [ruleName, setRuleName] = useState(
    isEditForm && selectedEventRule ? selectedEventRule.name : undefined
  );
  const [aircraftOptions, setAircraftOptions] = useState<CheckboxOptionType[]>([]);
  const [primaryConditionSourceOptions, setPrimaryConditionSourceOptions] = useState(
    isEditForm && selectedEventRule
      ? getInsightRuleConditionSourceOptions(selectedEventRule.primaryCondition.type)
      : undefined
  );
  const allEventParametersItems = useSelector(getInsightEventParameters);
  const userData = useSelector(getInsightUserData);
  const correctedEventParameterItems = allEventParametersItems.map(el => {
    const parameters = el.parameters.map(p => {
      const unit = getUserUnitForConditionType(p.name as InsightRuleConditionType, userData);
      return {
        ...p,
        displayUnit: unit
      };
    });
    return {
      ...el,
      parameters: parameters
    };
  });

  const [severityThresholdsChecked, setSeverityThresholdsChecked] = useState(false);

  useEffect(() => {
    if (selectedEventType && selectedEventType.key) {
      form.resetFields();
      setFieldsValue({
        ruleName: ruleName
      }); // Sets event name back to old value after resetting all fields
      setPrimaryConditionSourceOptions(getPrimaryConditionSourceOptions(selectedEventType.key));
      setAircraftOptions(
        buildAircraftOptions(
          aircraft,
          isEditForm && selectedEventRule ? selectedEventRule : undefined
        )
      );

      if (insightsSafetyEnabled) {
        setSeverityThresholdsChecked(
          (selectedEventRule &&
            selectedEventRule.primaryCondition.displayThreshold.severityEnabled) ||
            selectedEventRule === undefined
        );
      }
    }
  }, [aircraft, isEditForm, selectedEventType, selectedEventRule, insightsSafetyEnabled]);

  if (!visibility) {
    return null;
  }

  const closeDrawer = () => {
    onClose();
    setSelectedEventType(undefined);
    setRuleName(undefined);
    setPrimaryConditionSourceOptions(undefined);
  };

  const getErrorForThresholdValue = (
    higherThresholdValue: number,
    lowerThresholdValue: number,
    formItemFieldName: string
  ) => {
    const isNegative = higherThresholdValue < 0;
    if (Math.abs(higherThresholdValue) <= Math.abs(lowerThresholdValue)) {
      return {
        [formItemFieldName]: [
          {
            field: formItemFieldName,
            message: `Must be ${isNegative ? 'less' : 'greater'} than ` + lowerThresholdValue
          }
        ]
      };
    }
  };

  const validateSeverityThresholds = (fieldValues: any) => {
    if (fieldValues.severityDisplayThresholds) {
      const fieldNames = Object.keys(fieldValues.severityDisplayThresholds);
      for (const fieldName of fieldNames) {
        const thresholds = fieldValues.severityDisplayThresholds[fieldName];
        if (thresholds.low && thresholds.medium && thresholds.high) {
          const formItemFieldNameMedium = `severityDisplayThresholds.${fieldName}.medium`;
          const formItemFieldNameHigh = `severityDisplayThresholds.${fieldName}.high`;
          const mediumThresholdError = getErrorForThresholdValue(
            thresholds.medium,
            thresholds.low,
            formItemFieldNameMedium
          );
          if (mediumThresholdError) {
            setFields({
              [formItemFieldNameMedium]: {
                value: thresholds.medium,
                errors: [new Error(mediumThresholdError[formItemFieldNameMedium][0].message)]
              }
            });

            return mediumThresholdError;
          }

          const highThresholdError = getErrorForThresholdValue(
            thresholds.high,
            thresholds.medium,
            formItemFieldNameHigh
          );
          if (highThresholdError) {
            setFields({
              [formItemFieldNameHigh]: {
                value: thresholds.high,
                errors: [new Error(highThresholdError[formItemFieldNameHigh][0].message)]
              }
            });
          }

          return highThresholdError;
        }
      }
    }
  };

  const saveEventRule = async (e: any) => {
    e.preventDefault();
    setIsSaving(true);

    validateFields((err, fieldValues) => {
      if (!err) {
        err = validateSeverityThresholds(fieldValues);
      }

      if (err) {
        setIsSaving(false);
        return;
      }

      if (isEditForm && selectedEventRule != undefined) {
        // update
        const update = constructUpdateRule(
          organisationId,
          userData,
          fieldValues,
          aircraftOptions,
          selectedEventRule
        );
        dispatch(
          updateInsightRule(update, userData, () => {
            eventRuleUpdateSuccess();
            closeDrawer();
            setIsSaving(false);
          })
        );
        return;
      }

      // create
      const create = constructCreateRule(organisationId, userData, fieldValues, aircraftOptions);
      dispatch(
        createInsightRule(create, userData, () => {
          eventRuleCreateSuccess();
          closeDrawer();
          setIsSaving(false);
        })
      );
      return;
    });
  };

  const initialAircraft = isEditForm
    ? selectedEventRule!.allAircraft
      ? aircraftOptions.map(o => o.value)
      : selectedEventRule!.aircraftIds
    : [];
  const initialPrimaryConditionSource =
    isEditForm && selectedEventRule
      ? {
          key: selectedEventRule.primaryCondition.source,
          label: getPrimaryConditionSourceLabel(selectedEventRule.primaryCondition.source)
        }
      : undefined;
  const parameters =
    isEditForm && selectedEventRule
      ? selectedEventRule.parameters
      : (selectedEventType &&
          correctedEventParameterItems.filter(el => el.eventType == selectedEventType.key)) ??
        [];

  return (
    <Drawer
      className={'events-config-drawer'}
      title={isEditForm ? 'Edit' : 'Add'}
      placement="right"
      closable={true}
      visible={visibility}
      onClose={closeDrawer}
      getContainer={false}
      style={{ position: 'absolute' }}
      width={400}
      bodyStyle={{ width: '356px', margin: '20px 0 0 32px' }}
    >
      <Form onSubmit={saveEventRule} layout="vertical" hideRequiredMark={true}>
        <EventNameInput
          ruleName={ruleName}
          setRuleName={name => setRuleName(name)}
          getFieldDecorator={getFieldDecorator}
        />
        <EventTypeDropdown
          selectedEventType={selectedEventType}
          isEditForm={isEditForm}
          setSelectedEventType={item => setSelectedEventType(item)}
          getFieldDecorator={getFieldDecorator}
          eventTypes={eventTypes}
        />
        {selectedEventType && primaryConditionSourceOptions && (
          <PrimaryConditionSourceDropdown
            sources={primaryConditionSourceOptions}
            selectedSource={initialPrimaryConditionSource}
            getFieldDecorator={getFieldDecorator}
          />
        )}
        {insightsSafetyEnabled && selectedEventType && (
          <Row style={{ paddingBottom: '2rem' }}>
            <Checkbox
              data-testid="severity-thresholds-checkbox"
              checked={severityThresholdsChecked}
              onChange={() => setSeverityThresholdsChecked(prevState => !prevState)}
            >
              Enable Severity (<span style={{ color: 'green' }}>Low</span>/
              <span style={{ color: 'orange' }}>Medium</span>/
              <span style={{ color: 'red' }}>High</span>)
            </Checkbox>
          </Row>
        )}
        {selectedEventType &&
          parameters
            .filter(parameter => isEditForm || parameter.eventType === selectedEventType.key)
            .map((p, i) => {
              return (
                <ParameterWithThresholds
                  key={`param#${i}`}
                  parameters={p.parameters as any}
                  getFieldDecorator={getFieldDecorator as any}
                  setFieldsValue={setFieldsValue as any}
                  severityThresholdsEnabled={severityThresholdsChecked}
                />
              );
            })}
        {selectedEventType && (
          <AircraftSelector
            form={form}
            initialValue={initialAircraft}
            isEditForm={isEditForm}
            options={aircraftOptions}
            showAllChoice={true}
          />
        )}
        <ConfirmationButtons onClose={closeDrawer} isSaving={isSaving} />
        {selectedEventType && primaryConditionSourceOptions && (
          <span>
            Inputs marked with <span style={{ color: 'red' }}>*</span> come from 3rd party sources
          </span>
        )}
      </Form>
    </Drawer>
  );
};

export default Form.create<EventsConfigDrawerProps>()(EventsConfigDrawer);
